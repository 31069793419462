.root {
  align-items: center;
  background-color: var(--color-bg-primary);
  border-radius: var(--rounded-lg);
  display: grid;
  grid-template-columns: 25% 75%;
  /* TODO: Make height dynamic */
  /* Viewport size minus the height of other vertical elements (action bar, tabs, header) */
  height: calc(100vh - 290px);
  overflow-x: hidden;

  &.fullscreen {
    height: 100%;
  }
}

/* Need to override existing specificity with chained class name */
.dragOverlay.dragOverlay.dragOverlay {
  & > div {
    border: 2px solid var(--color-action);
    border-radius: var(--rounded-md);

    & button {
      background: white;
      border: none;
      cursor: grabbing;
    }
  }
}
