.icon {
  background: var(--color-bg-primary);
  color: var(--color-text-heading);
  cursor: pointer;
}

.root {
  & input {
    color: var(--color-text-heading);
    text-align: center;
  }
}
