.active {
  border-bottom: 2px solid var(--color-action);
  color: var(--color-text-heading);
  font-weight: var(--font-semibold);
  width: 100%;
}

.content {
  flex-grow: 0;
  /* Subtract the height of the tabs */
  height: calc(100% - 56px);
  margin-right: var(--spacing-2);
  margin-top: var(--spacing-2);
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: var(--spacing-5) var(--spacing-2) var(--spacing-6) var(--spacing-5);

  &::-webkit-scrollbar {
    flex-grow: 0;
    overflow-x: hidden;
    position: static;
    width: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-slate-400);
    border-radius: var(--rounded-lg);
    flex-grow: 0;
    overflow-x: hidden;
    position: static;
  }
}

.tabs {
  border-bottom: var(--border-decorative-secondary);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: var(--font-medium);
  padding-inline-start: 0px;

  & li {
    padding: var(--spacing-3) var(--spacing-6);
    text-align: center;
    width: 50%;
  }

  & > :first-child {
    border-right: var(--border-decorative-secondary);
  }
}
